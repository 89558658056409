<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.name") }}</div>
        <div class="form-item__field">
          <input
            v-model.trim.lazy="$v.name.$model"
            :class="{ invalid: $v.name.$error }"
            :placeholder="$t('profile.name_placeholder')"
            type="text"
          />
          <small v-if="$v.name.$error" class="error">{{
            $t("profile.name_required")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">E-mail</div>
        <div class="form-item__field">
          <input :value="email" disabled type="email" />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Telegram</div>
        <div class="form-item__field">
          <input
            v-model.trim="telegram"
            :placeholder="$t('profile.telegram_placeholder')"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Skype</div>
        <div class="form-item__field">
          <input
            v-model.trim="skype"
            :placeholder="$t('profile.skype_placeholder')"
            type="text"
          />
        </div>
      </div>
    </div>
    <div class="col-12">
      <button class="btn _mob100" @click="save">{{ $t("save") }}</button>
    </div>
    <notifications group="success" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import PanelService from "@/api/servicePanel";
import { minLength, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";

export default {
  mixins: [validationMixin],
  validations: {
    name: {
      minLength: minLength(2),
      required,
    },
  },
  data: () => ({
    name: "",
    email: "",
    skype: "",
    telegram: "",
  }),
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
  },
  watch: {
    user: {
      immediate: true,
      handler() {
        this.setStartParams();
      },
    },
  },
  methods: {
    ...mapActions({
      setUser: "user/setUser",
    }),
    setStartParams() {
      this.name = this.user.name;
      this.email = this.user.email;
      this.skype = this.user.skype;
      this.telegram = this.user.telegram;
      this.name = this.user.name;
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        name: this.name,
        telegram: this.telegram,
        skype: this.skype,
      };
      PanelService.updateUserData(payload).then((res) => {
        if (res && res.status === 200) {
          this.$notify({
            group: "success",
            ignoreDuplicates: true,
            title: this.$t("profile.updated_personal_data"),
          });
          this.setUser();
        }
      });
    },
  },
};
</script>