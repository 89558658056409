<template>
  <div class="row">
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Webmoney WMZ</div>
        <div class="form-item__field">
          <input
            id="webmoney"
            v-model.trim="$v.webmoney.$model"
            :class="{ invalid: $v.webmoney.$error }"
            :disabled="webmoneyDisable"
            maxlength="13"
            type="text"
          />
          <small v-if="$v.webmoney.$invalid" class="error">{{
            $t("profile.notif_webmoney")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">
          {{ $t("profile.on_balance") }}
          Push.House
        </div>
        <div class="form-item__field">
          <input
            v-model.trim="$v.ph.$model"
            :class="{ invalid: $v.ph.$error }"
            :disabled="phDisable"
            type="email"
          />
          <small v-if="$v.ph.$invalid" class="error">{{
            $t("profile.notif_ph")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Capitalist USD</div>
        <div class="form-item__field">
          <input
            v-model.trim="$v.capitalist.$model"
            :class="{ invalid: $v.capitalist.$error }"
            :disabled="capitalistDisable"
            maxlength="9"
            type="text"
          />
          <small v-if="$v.capitalist.$invalid" class="error">{{
            $t("profile.notif_capitalist")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.bankcard") }}</div>
        <div class="form-item__field">
          <input
            v-model.trim="$v.card.$model"
            :class="{ invalid: $v.card.$error }"
            :disabled="cardDisable"
            :maxlength="16"
            type="text"
            @input="checkSymbols($event, 'card')"
          />
          <small v-if="$v.card.$invalid" class="error">{{
            $t("profile.notif_card")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Qiwi</div>
        <div class="form-item__field">
          <input
            @input="checkSymbols($event, 'qiwi')"
            v-model.trim="$v.qiwi.$model"
            :class="{ invalid: $v.qiwi.$invalid }"
            :disabled="qiwiDisable"
            :maxlength="11"
            type="text"
          />
          <small v-if="$v.qiwi.$invalid" class="error" :class="{'uk':$i18n.locale === 'uk'}">{{
            $t("profile.notif_qiwi")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">Yandex.Money</div>
        <div class="form-item__field">
          <input
            @input="checkSymbols($event, 'yandex')"
            v-model.trim="$v.yandex.$model"
            :class="{ invalid: $v.yandex.$error }"
            :disabled="yandexDisable"
            :maxlength="14"
            type="text"
          />
          <small v-if="$v.yandex.$invalid" class="error">{{
            $t("profile.notif_yandex")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.bankcard_rf") }}</div>
        <div class="form-item__field">
          <input
            @input="checkSymbols($event, 'cardRu')"
            v-model.trim="$v.cardRu.$model"
            :class="{ invalid: $v.cardRu.$error }"
            :disabled="cardRuDisable"
            :maxlength="16"
            type="text"
          />
          <small v-if="$v.cardRu.$invalid" class="error">{{
            $t("profile.notif_card_ru")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-item pt-10">
        <div class="form-item__label">USDT (trc20)</div>
        <div class="form-item__field">
          <input
            v-model.trim="$v.usdt.$model"
            :class="{ invalid: $v.usdt.$error }"
            :disabled="usdtDisable"
            :maxlength="34"
            type="text"
          />
          <small v-if="$v.usdt.$invalid" class="error">{{
            $t("profile.notif_usdt")
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12">
      <button class="btn _mob100" @click="save">
        {{ $t("save") }}
      </button>
    </div>
    <notifications group="info" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import PanelService from "@/api/servicePanel";
import { email, helpers, maxLength, minLength } from "vuelidate/lib/validators";

const webMoneyRegex = helpers.regex("webmoney", /^Z[0-9]*$/);
const capitalistRegex = helpers.regex("capitalist", /^U[0-9]*$/);
const cardRegex = helpers.regex("card", /[0-9]*$/);
const qiwiRegex = helpers.regex("qiwi", /[0-9]*$/);
const yandexRegex = helpers.regex("yandex", /^4100[0-9]*$/);
const usdtRegex = helpers.regex("usdt", /^T[a-zA-Z[0-9]*$/);

export default {
  mixins: [validationMixin],
  validations: {
    ph: {
      email,
    },
    webmoney: {
      webMoneyRegex,
      minLength: minLength(13),
      maxLength: maxLength(13),
    },
    capitalist: {
      capitalistRegex,
      minLength: minLength(9),
      maxLength: maxLength(9),
    },
    card: {
      cardRegex,
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    cardRu: {
      cardRegex,
      minLength: minLength(16),
      maxLength: maxLength(16),
    },
    qiwi: {
      qiwiRegex,
      minLength: minLength(11),
      maxLength: maxLength(11),
    },
    yandex: {
      yandexRegex,
      minLength: minLength(14),
      maxLength: maxLength(14),
    },
    usdt: {
      usdtRegex,
      minLength: minLength(34),
      maxLength: maxLength(34),
    },
  },
  data() {
    return {
      webmoney: "",
      ph: "",
      capitalist: "",
      qiwi: "",
      card: "",
      cardRu: "",
      yandex: "",
      usdt: "",
      webmoneyDisable: false,
      phDisable: false,
      capitalistDisable: false,
      cardDisable: false,
      cardRuDisable: false,
      qiwiDisable: false,
      yandexDisable: false,
      usdtDisable: false,
    };
  },
  methods: {
    checkSymbols($event, field) {
      let reg = /^[0-9]+(\.[0-9]*)?$/;
      if (this[field].match(reg)) return this[field];
      else this[field] = this[field].replace($event.data, "");
    },
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      if (
        this.notValidCard ||
        this.notValidCardRu ||
        this.notValidQiwi ||
        this.notValidYandex
      ) {
        this.$notify({
          group: "info",
          type: "error",
          ignoreDuplicates: true,
          title: this.$t("sent_error"),
          text: this.$t("profile.not_valid"),
        });
        return;
      }

      if (
        this.webmoneyDisable &&
        this.capitalistDisable &&
        this.phDisable &&
        this.cardDisable &&
        this.cardRuDisable &&
        this.qiwiDisable &&
        this.yandexDisable &&
        this.usdtDisable
      ) {
        this.$notify({
          group: "info",
          type: "error",
          ignoreDuplicates: true,
          title: this.$t("sent_error"),
          text: this.$t("no_changes"),
        });
        return;
      }

      const payload = {
        wallets: {
          webmoney: this.webmoney,
          ph: this.ph,
          capitalist: this.capitalist,
          qiwi: this.qiwi,
          card: this.card,
          cardRu: this.cardRu,
          yandex: this.yandex,
          usdt: this.usdt,
        },
      };

      PanelService.updateWallets(payload).then((res) => {
        if (res.status === 200) {
          this.$notify({
            group: "info",
            ignoreDuplicates: true,
            title: this.$t("profile.updated_payments"),
          });
        }
        if (res.status === 400) {
          this.addInputError(res.data);
        }
      });
    },
    setWallet(array) {
      if (array.length) {
        array.map((el) => {
          this[el.type] = el.wallet;
          const field = el.type + "Disable";
          this[field] = true;
        });
      }
    },
    getWallets() {
      PanelService.getWallets().then((res) => {
        if (res && res.status === 200) {
          if (res.data.length) {
            this.setWallet(res.data);
          }
        }
      });
    },
  },
  created() {
    this.getWallets();
  },
};
</script>

<style scoped>
  small.uk {
    font-size: 11px;
  }
</style>