<template>
  <div class="row">
    <div class="col-12">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.current_pass") }}</div>
        <div class="form-item__field">
          <input
            ref="currentPass"
            v-model.trim.lazy="$v.currentPass.$model"
            :class="{ invalid: $v.currentPass.$error || invalidCurrent }"
            maxlength="32"
            type="password"
          />
          <button
            class="btn btn-empty"
            @click="toggleVisibility('currentPass')"
          >
            <svg
              v-svg
              :symbol="toggleEye ? 'view-1' : 'view-2'"
              class="icon-pass"
              role="presentation"
              size="0 0 18 16"
            />
          </button>
          <small v-if="$v.currentPass.$error" class="error">{{
            $t("min_len_pass", { number: "3" })
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.new_pass") }}</div>
        <div class="form-item__field">
          <input
            ref="newPass"
            v-model.trim.lazy="$v.newPass.$model"
            :class="{ invalid: $v.newPass.$error }"
            maxlength="32"
            type="password"
          />
          <button class="btn btn-empty" @click="toggleVisibility('newPass')">
            <svg
              v-svg
              :symbol="toggleEye2 ? 'view-1' : 'view-2'"
              class="icon-pass"
              role="presentation"
              size="0 0 18 16"
            />
          </button>
          <small v-if="$v.newPass.$error" class="error">{{
            $t("min_len_pass", { number: "3" })
          }}</small>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-item pt-10">
        <div class="form-item__label">{{ $t("profile.repeat_new_pass") }}</div>
        <div class="form-item__field">
          <input
            ref="repeatPass"
            v-model.trim.lazy="$v.repeatPass.$model"
            :class="{ invalid: $v.repeatPass.$error }"
            maxlength="32"
            type="password"
          />
          <button class="btn btn-empty" @click="toggleVisibility('repeatPass')">
            <svg
              v-svg
              :symbol="toggleEye3 ? 'view-1' : 'view-2'"
              class="icon-pass"
              role="presentation"
              size="0 0 18 16"
            />
          </button>
          <small
            v-if="!$v.repeatPass.sameAs && $v.repeatPass.$error"
            class="error"
            >{{ $t("profile.not_same_pass") }}</small
          >
        </div>
      </div>
    </div>
    <div class="col-12">
      <button class="btn _mob100" @click="save">{{ $t("save") }}</button>
    </div>
    <notifications group="message" />
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import PanelService from "@/api/servicePanel";
import {
  maxLength,
  minLength,
  required,
  sameAs,
} from "vuelidate/lib/validators";

export default {
  mixins: [validationMixin],
  validations: {
    currentPass: {
      required,
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
    newPass: {
      minLength: minLength(3),
      maxLength: maxLength(32),
      required,
    },
    repeatPass: {
      required,
      sameAs: sameAs("newPass"),
      minLength: minLength(3),
      maxLength: maxLength(32),
    },
  },

  data: () => ({
    currentPass: "",
    newPass: "",
    repeatPass: "",
    invalidCurrent: false,
    toggleEye: false,
    toggleEye2: false,
    toggleEye3: false,
  }),
  methods: {
    save() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const payload = {
        current_password: this.currentPass,
        new_password: this.newPass,
        repeat_password: this.repeatPass,
      };
      PanelService.updatePassword(payload).then((res) => {
        if (res && res.status === 400) {
          this.$notify({
            group: "message",
            type: "error",
            ignoreDuplicates: true,
            title: this.$t("sent_error"),
            text: res.data.currentPassword,
          });
          if (res.data.currentPassword) {
            this.invalidCurrent = true;
          }
        } else if (res && res.status === 200) {
          this.$notify({
            group: "message",
            ignoreDuplicates: true,
            title: this.$t("profile.updated_password"),
          });
        }
      });
    },
    toggleVisibility(input) {
      if (input === "currentPass") {
        this.toggleEye = !this.toggleEye;
      }
      if (input === "newPass") {
        this.toggleEye2 = !this.toggleEye2;
      }
      if (input === "repeatPass") {
        this.toggleEye3 = !this.toggleEye3;
      }

      this.$refs[input].type == "password"
        ? (this.$refs[input].type = "text")
        : (this.$refs[input].type = "password");
    },
  },
};
</script>

<style lang="scss" scoped>
.form-item__field {
  input {
    padding-right: 50px;
  }
}

.btn.btn-empty {
  padding: 0 15px;
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  height: 45px;
  min-height: 45px;
}
</style>